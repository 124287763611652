import styled from 'styled-components';
import { SplitButton, Button } from 'react-bootstrap';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
`;

export const ButtonTransmitirNFe = styled.button`
  padding: 3px 12px;
  height: 40px;
  min-width: 200px;
  border-radius: 4px;
  gap: 5px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;
  animation: all 0.25s ease-in;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    opacity: 0.7;
  }
  &.visualizarDanfe {
    background: #007bff;
  }
  &.transmitirNfe {
    background: #dc3545;
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const SplitButtonTransmitirNFe = styled(SplitButton)`
  padding: 3px 12px;
  height: 40px;
  min-width: 200px;
  border-radius: 4px;
  gap: 5px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;
  animation: all 0.25s ease-in;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    opacity: 0.7;
  }

  &.transmitirNfe {
    &:hover {
      opacity: 1;
    }
    padding: 0;
    background: #dc3545;
    button {
      &:hover {
        background-color: #c42f3d !important;
        border-color: #c42f3d !important;
      }
      border: none;
      box-shadow: none !important;
      background: #dc3545;
    }
    #dropdown-split-variants-transmissao {
      color: #fff;
      &:hover {
        background-color: #c42f3d !important;
        border-color: #c42f3d !important;
      }
      background-color: #dc3545 !important;
      border-color: #dc3545 !important;
    }
    .dropdown-menu {
      /* background: #007bff; */
      a {
        &:hover {
          opacity: 0.8;
        }
        &:active {
          background-color: #fff;
          color: #212529;
        }
        /* background: #007bff; */
        /* color: #fff; */
      }
    }
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    opacity: 0.7;
  }

  .split-main {
    display: flex;
    flex-direction: row;
    gap: 3px;
    justify-content: center;
    align-items: center;
  }
`;
export const CloseButton = styled(Button)`
  background-color: transparent;
  color: #000;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: #504e4e;
    box-shadow: none !important;
  }
`;
