import React, { useState, useCallback, useEffect } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { upperCase } from 'lodash';
import icones from '~/config/icones';
import { Container, Main } from './styles';
import { IModulosTelas, ITela } from './types';

/**
 * @param label Nome que será exibido na parte superior do componente
 * @function onChange Função que atualiza o valor do input
 */
const TreeView: React.FC<any> = (props) => {
  const { label, arryModulosTelas, onChange, invalid, className } = props;

  const [telas, setTelas] = useState<Array<IModulosTelas>>([]);

  const [allModulos, setAllModulos] = useState<boolean | undefined>(undefined);

  const sortArrays = useCallback(
    (array: any[], key: 'num_ordem_menu' | 'text'): any[] => {
      return array.sort((a, b) => {
        if (key === 'num_ordem_menu') {
          if (a.num_ordem_menu !== null && b.num_ordem_menu !== null)
            return a.num_ordem_menu - b.num_ordem_menu;
          if (a.num_ordem_menu === null && b.num_ordem_menu === null)
            return a.text.localeCompare(b.text);
          return a.num_ordem_menu === null ? 1 : -1;
        }
        return a.text.localeCompare(b.text);
      });
    },
    [],
  );

  useEffect(() => {
    let modulosCheck = 0;
    arryModulosTelas.forEach((element: IModulosTelas) => {
      if (element.check === true) {
        modulosCheck++;
      }
    });

    if (modulosCheck !== arryModulosTelas.length) {
      setAllModulos(undefined);
    } else {
      setAllModulos(true);
    }
    setTelas(sortArrays(arryModulosTelas, 'num_ordem_menu'));
  }, [arryModulosTelas, sortArrays]);

  function addEvent(id: string): any {
    const retId = document.querySelector(`#${id}`);
    retId?.classList.toggle('isActive');

    const iconRotate = retId?.parentNode?.querySelector('.icon');
    iconRotate?.classList.toggle('rotateIcon');
  }

  useEffect(() => {
    sortArrays(telas, 'num_ordem_menu');
    onChange(telas);
  }, [onChange, sortArrays, telas]);

  /*
   ** Ação ao clicar em uma tela
   */

  const CheckTela = useCallback(
    (telaClicked, telaClikModulo) => {
      const telaOnChanged = {
        id: telaClicked.id,
        text: telaClicked.text,
        state: 1,
        isLeaf: telaClicked.isLeaf,
        lib_icon: telaClicked.lib_icon,
        des_icon: telaClicked.des_icon,
        num_ordem_menu: telaClicked.num_ordem_menu,
        check: true,
      };

      const filteredModule = telas.filter((val: any) => {
        return val.text !== telaClikModulo.text;
      });

      const filteredForCheck = telaClikModulo.children.filter((val: any) => {
        return val.id !== telaClicked.id;
      });

      const filteredForCheckLenght = filteredForCheck.length;
      let countTelaChckd = 0;
      let telaClikModuloChecked = telaClikModulo;

      for (let index = 0; index < filteredForCheck.length; index++) {
        if (filteredForCheck[index].check === true) {
          countTelaChckd++;
        }
        if (filteredForCheckLenght === countTelaChckd) {
          telaClikModuloChecked = { ...telaClikModulo, check: true };
        }
      }

      filteredForCheck.push(telaOnChanged);

      setTelas(
        sortArrays(
          [
            ...filteredModule,
            {
              ...telaClikModuloChecked,
              children: sortArrays(filteredForCheck, 'num_ordem_menu'),
            },
          ],
          'num_ordem_menu',
        ),
      );
    },
    [sortArrays, telas],
  );
  const unchckTela = useCallback(
    (telaClicked, telaClikModulo) => {
      const telaOnChanged = {
        id: telaClicked.id,
        text: telaClicked.text,
        state: 2,
        isLeaf: telaClicked.isLeaf,
        lib_icon: telaClicked.lib_icon,
        des_icon: telaClicked.des_icon,
        num_ordem_menu: telaClicked.num_ordem_menu,
        check: undefined,
      };

      const filteredModule = telas.filter((val: any) => {
        return val.text !== telaClikModulo.text;
      });

      const filteredForCheck = telaClikModulo.children.filter((val: any) => {
        return val.id !== telaClicked.id;
      });

      let telaClikModuloChecked = telaClikModulo;

      telaClikModuloChecked = { ...telaClikModulo, check: undefined };

      filteredForCheck.push(telaOnChanged);

      setTelas(
        sortArrays(
          [
            ...filteredModule,
            {
              ...telaClikModuloChecked,
              children: sortArrays(filteredForCheck, 'num_ordem_menu'),
            },
          ],
          'num_ordem_menu',
        ),
      );
    },
    [sortArrays, telas],
  );

  const clickCheckTela = useCallback(
    (telaClicked, telaClikModulo) => {
      if (
        telaClicked.check === undefined ||
        telaClicked.check === false ||
        telaClicked.state === 2
      ) {
        CheckTela(telaClicked, telaClikModulo);
      } else {
        unchckTela(telaClicked, telaClikModulo);
      }
    },
    [CheckTela, unchckTela],
  );

  /*
   ** Ação ao clicar em um modulo
   */
  const clickCheckModulo = useCallback(
    (modulClicked) => {
      if (modulClicked.check === undefined || modulClicked.check === false) {
        CheckModulo(modulClicked);
      } else {
        unchckModulo(modulClicked);
      }
    },
    [telas],
  );

  const CheckModulo = useCallback(
    (muduleClick: any) => {
      const arr2: any = [];
      muduleClick.children.forEach((elementTelas: any) => {
        const telaMarcado = {
          id: elementTelas.id,
          text: elementTelas.text,
          des_tela: elementTelas.des_tela,
          lib_icon: elementTelas.lib_icon,
          des_icon: elementTelas.des_icon,
          isLeaf: elementTelas.isLeaf,
          num_ordem_menu: elementTelas.num_ordem_menu,
          check: true,
          state: 1,
        };
        arr2.push(telaMarcado);
      });

      const filteredModule = telas.filter((val: IModulosTelas) => {
        return val.text !== muduleClick.text;
      });

      const moduloAtualizado = {
        text: muduleClick.text,
        isLeaf: muduleClick.isLeaf,
        check: true,
        num_ordem_menu: muduleClick.num_ordem_menu,
        children: arr2,
      };

      setTelas(
        sortArrays([...filteredModule, moduloAtualizado], 'num_ordem_menu'),
      );

      let contModulos = 1;

      telas.forEach((element: IModulosTelas) => {
        if (element.check === true) {
          contModulos++;
        }
      });

      if (contModulos === telas.length) {
        setAllModulos(true);
      }
    },
    [sortArrays, telas],
  );
  const unchckModulo = useCallback(
    (muduleClick: IModulosTelas) => {
      const arr2: any = [];
      muduleClick.children.forEach((elementTelas: any) => {
        const telaMarcado = {
          id: elementTelas.id,
          text: elementTelas.text,
          des_tela: elementTelas.des_tela,
          lib_icon: elementTelas.lib_icon,
          des_icon: elementTelas.des_icon,
          isLeaf: elementTelas.isLeaf,
          num_ordem_menu: elementTelas.num_ordem_menu,
          check: undefined,
          state: 2,
        };
        arr2.push(telaMarcado);
      });

      const filteredModule = telas.filter((val: IModulosTelas) => {
        return val.text !== muduleClick.text;
      });

      const moduloAtualizado = {
        text: muduleClick.text,
        isLeaf: muduleClick.isLeaf,
        check: undefined,
        num_ordem_menu: muduleClick.num_ordem_menu,
        children: arr2,
      };
      setTelas(
        sortArrays([...filteredModule, moduloAtualizado], 'num_ordem_menu'),
      );

      setAllModulos(undefined);
    },
    [sortArrays, telas],
  );

  /*
   ** Ação ao clicar em marcar todos
   */

  const CheckAll = useCallback(() => {
    const arr: any = [];
    telas.forEach((element) => {
      const arr2: any = [];
      element.children.forEach((elementTelas) => {
        const telaMarcado = {
          id: elementTelas.id,
          text: elementTelas.text,
          des_tela: elementTelas.des_tela,
          lib_icon: elementTelas.lib_icon,
          des_icon: elementTelas.des_icon,
          isLeaf: elementTelas.isLeaf,
          num_ordem_menu: elementTelas.num_ordem_menu,
          check: true,
          state: 1,
        };
        arr2.push(telaMarcado);
      });
      const moduloMarcado = {
        text: element.text,
        isLeaf: element.isLeaf,
        check: true,
        num_ordem_menu: element.num_ordem_menu,
        children: arr2,
      };

      arr.push(moduloMarcado);
    });
    setTelas(sortArrays(arr, 'num_ordem_menu'));
  }, [sortArrays, telas]);

  const unchckAll = useCallback(() => {
    const arr: any = [];
    telas.forEach((element: IModulosTelas) => {
      const arr2: any = [];
      element.children.forEach((elementTelas: ITela) => {
        const telaMarcado = {
          id: elementTelas.id,
          text: elementTelas.text,
          des_tela: elementTelas.des_tela,
          lib_icon: elementTelas.lib_icon,
          des_icon: elementTelas.des_icon,
          isLeaf: elementTelas.isLeaf,
          num_ordem_menu: elementTelas.num_ordem_menu,
          check: undefined,
          state: 2,
        };
        arr2.push(telaMarcado);
      });
      const moduloMarcado = {
        text: element.text,
        isLeaf: element.isLeaf,
        check: undefined,
        num_ordem_menu: element.num_ordem_menu,
        children: arr2,
      };

      arr.push(moduloMarcado);
    });
    setTelas(sortArrays(arr, 'num_ordem_menu'));
  }, [sortArrays, telas]);

  const clickCheckAll = useCallback(() => {
    if (allModulos === undefined || allModulos === false) {
      setAllModulos(true);
      CheckAll();
    } else {
      setAllModulos(undefined);
      unchckAll();
    }
  }, [CheckAll, allModulos, unchckAll]);

  return (
    <Container>
      <header>
        <label htmlFor="labelHeader">{label}</label>
        <label className="labelHeader">
          Marcar todas
          <input
            type="checkbox"
            checked={!!allModulos}
            onClick={clickCheckAll}
          />
        </label>
      </header>

      <Main
        style={invalid ? { borderBottom: '3px solid #D12525' } : undefined}
        className={className}
      >
        {telas.map((telaModulo: any) => {
          return (
            <ul key={telaModulo.text}>
              <li className="header-list contents-list">
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      addEvent(
                        telaModulo.text.toUpperCase().replace(/\s+/g, ''),
                      );
                    }}
                  >
                    <FiChevronRight className="icon" />
                    <label htmlFor={telaModulo.text}> {telaModulo.text} </label>
                  </button>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name={telaModulo.text}
                    checked={!!telaModulo.check}
                    onChange={() => clickCheckModulo(telaModulo)}
                  />
                </div>
              </li>
              <ul
                id={telaModulo.text.toUpperCase().replace(/\s+/g, '')}
                className="isActive"
              >
                {telaModulo.children.map(
                  (tela: {
                    id: number;
                    text: string;
                    des_tela: string;
                    lib_icon: string;
                    des_icon: string;
                    isLeaf: boolean;
                    check: boolean;
                    state: number;
                    num_ordem_menu: number | null;
                  }) => {
                    const { lib_icon, des_icon } = {
                      des_icon: tela.des_icon,
                      lib_icon: tela.lib_icon,
                    };
                    const iconkey = Object.keys(icones).find(
                      (icon) => icon === lib_icon,
                    );

                    let Icon = '';
                    if (iconkey) {
                      const Lib = icones[iconkey];
                      Icon = Lib[des_icon];
                    }

                    return (
                      <li
                        className="header-list contents-sub-list"
                        key={tela.id}
                      >
                        <div>
                          <label htmlFor={String(tela.id)}>
                            {Icon && <Icon />}
                            {tela.text}{' '}
                          </label>
                        </div>
                        <input
                          type="checkbox"
                          id={String(tela.id)}
                          name={tela.text}
                          checked={!!tela.check}
                          onChange={() => clickCheckTela(tela, telaModulo)}
                        />
                      </li>
                    );
                  },
                )}
              </ul>
            </ul>
          );
        })}
      </Main>
    </Container>
  );
};

export default TreeView;
