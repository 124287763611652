import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  TransmitirNFeContextDataProps,
  TransmitirNFeContextProviderProps,
} from './protocols';
import api from '~/services/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

export const TransmitirNFeContext = createContext(
  {} as TransmitirNFeContextDataProps,
);

export function TransmitirNFeContextProvider({
  children,
  codSeqNf,
  waitForCompletion,
}: TransmitirNFeContextProviderProps): JSX.Element {
  const [codLoja, setCodLoja] = useState<number | undefined>();
  const [codPessoa, setCodPessoa] = useState<number | undefined>();
  const [numNf, setNumNf] = useState<number | undefined>();
  const [tipoNf, setTipoNf] = useState<number | undefined>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [flgNfePendente, setFlgNfePendente] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);
  const [loadingEmail, setLoadingEmail] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenModalEmail, setIsOpenModalEmail] = useState<boolean>(false);
  const [isDanfeValidated, setIsDanfeValidated] = useState<boolean>(false);
  const [pdf, setPdf] = useState<any>(undefined);
  const [tags, setTags] = useState<string[]>([]);
  const [desLoja, setDesLoja] = useState<string>('');
  const [emailDestinatario, setEmailDestinatario] = useState<string>('');

  useEffect(() => {
    if (codSeqNf !== undefined) {
      (async () => {
        const { data } = await api.get(`/transmitir-nfe/nf/${codSeqNf}`);

        if (!data.data) return;

        const {
          flg_nfe_pendente,
          flg_cancelada,
          tipo_emissao,
          des_especie,
          email,
          cod_loja,
          des_loja,
          cod_pessoa,
          num_nf,
          tipo_nf,
        } = data.data;

        if (flg_cancelada || des_especie !== 'NFE' || tipo_emissao !== 1) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
        setCodLoja(cod_loja);
        setDesLoja(des_loja);
        setCodPessoa(cod_pessoa);
        setNumNf(num_nf);
        setTipoNf(tipo_nf);
        setFlgNfePendente(flg_nfe_pendente);
        setEmailDestinatario(email);
        if (!flg_nfe_pendente) {
          setIsDanfeValidated(true);
        }

        if (/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
          setTags((prevTags) => [...prevTags, email]);
        }
      })();
    }
  }, [codSeqNf]);

  const digitalCertificateValidation = useCallback(async () => {
    try {
      if (codLoja) {
        const res = await api.get(
          `/transmitir-nfe/validacao-certificado-digita/${codLoja}`,
        );
        const { success, data } = res.data;
        if (success) {
          if (Number(data.tot) > 0) {
            return true;
          }
        }
      }
      throw new Error(
        'Certificado digital não configurado para a loja. Vá até a tela "Parametrização de NF-e" e regularize a situação.',
      );
    } catch (error: any) {
      Swal.fire({
        text: error.message,
        icon: 'warning',
        showConfirmButton: false,
      });

      return false;
    }
  }, [codLoja]);

  const transmitirNFe = useCallback(async () => {
    try {
      if (!(await digitalCertificateValidation())) return;

      setLoading(true);
      setLoadingPDF(true);

      const res = await api.post('/transmitir-nfe', {
        cod_loja: codLoja,
        cod_pessoa: codPessoa,
        num_nf: numNf,
        tipo_nf: tipoNf,
        cod_seq_nf: codSeqNf,
      });

      if (res.data.success) {
        setPdf(res.data.danfe);
        setIsDanfeValidated(true);
        setFlgNfePendente(false);
        setIsOpenModal(true);
        setLoading(false);
        setLoadingPDF(false);
        if (waitForCompletion) waitForCompletion();
      }
    } catch (error: any) {
      setLoading(false);
      setLoadingPDF(false);
      if (waitForCompletion) waitForCompletion();
    }
  }, [
    digitalCertificateValidation,
    codLoja,
    codPessoa,
    numNf,
    tipoNf,
    codSeqNf,
    waitForCompletion,
  ]);

  const visualizarDanfe = useCallback(
    async (tipo: 'conferencia' | 'final') => {
      try {
        setLoadingPDF(true);
        setLoading(true);

        const { data } = await api.get(
          '/transmitir-nfe/visulizar-danfe-sefaz',
          {
            params: {
              cod_loja: codLoja,
              cod_pessoa: codPessoa,
              num_nf: numNf,
              tipo_nf: tipoNf,
              cod_seq_nf: codSeqNf,
              tipo,
            },
          },
        );
        if (data.success) {
          setPdf(data.danfe);
          setIsOpenModal(true);
          setLoadingPDF(false);
          setLoading(false);
          if (waitForCompletion) waitForCompletion();
        }
      } catch (error: any) {
        setLoadingPDF(false);
        setLoading(false);
        if (waitForCompletion) waitForCompletion();
      }
    },
    [codLoja, codPessoa, codSeqNf, numNf, tipoNf, waitForCompletion],
  );

  const handleSendEmail = useCallback(async () => {
    try {
      setLoadingEmail(true);
      const result = await api.get('/transmitir-nfe/enviar-danfe-via-email', {
        params: {
          emails: tags,
          cod_seq_nf: codSeqNf,
          cod_loja: codLoja,
          des_loja: desLoja,
        },
      });
      if (result.data.success) {
        toast.success(result.data.message);
      }
      setTimeout(() => {
        setLoadingEmail(false);
      }, 1500);
    } catch (error: any) {
      if (error?.message) toast.error(String(error?.message));
      if (error?.data.message) toast.error(String(error?.data?.message));
      setTimeout(() => {
        setLoadingEmail(false);
      }, 1500);
    }
  }, [tags, codSeqNf, codLoja, desLoja]);
  // tags, numNf, codLoja

  return (
    <TransmitirNFeContext.Provider
      value={{
        loading,
        loadingModal,
        loadingPDF,
        isDanfeValidated,
        codSeqNf,
        flgNfePendente,
        setFlgNfePendente,
        disabled,
        transmitirNFe,
        visualizarDanfe,
        isOpenModal,
        setIsOpenModal,
        pdf,
        isOpenModalEmail,
        setIsOpenModalEmail,
        tags,
        setTags,
        handleSendEmail,
        loadingEmail,
        setLoadingEmail,
      }}
    >
      {children}
    </TransmitirNFeContext.Provider>
  );
}

export const useTransmitirNFe = (): TransmitirNFeContextDataProps => {
  return useContext(TransmitirNFeContext);
};
