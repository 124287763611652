import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const ContainerModal = styled.div`
  height: 550px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 100px;
  .row {
    align-items: flex-end;
    margin-bottom: 20px;
  }
  .btn {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    .btn-icon {
      margin-left: 0.5rem;
    }
  }
  .btn-impressao {
    background-color: #2d136c;
    border-color: #200e49;
    &:focus {
      box-shadow: 0 0 0 0.25rem #c2a0f8;
    }
  }
  .btn-email {
    background-color: #2d136c;
    border-color: #200e49;
  }
`;
export const ContainerPdf = styled.div`
  width: 100%;
  height: 500px;
  overflow: auto;
  border: 1px solid #dfdfdf;
`;

export const ModalActionPDF = styled(Modal)`
  z-index: 1040;
  .modal-header {
    border-bottom: transparent;
  }
`;

export const ButtonTransmitirNFe = styled.button`
  padding: 3px 12px;
  height: 40px;
  min-width: 200px;
  border-radius: 4px;
  gap: 5px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;
  animation: all 0.25s ease-in;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    opacity: 0.7;
  }
  &.visualizarDanfe {
    background: #007bff;
  }
  &.transmitirNfe {
    background: #dc3545;
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const ContainerButtonLeft = styled.div`
  width: 100%;
  display: flex;
  flex-direction: rows;
  align-items: flex-end;
  justify-content: flex-start;
`;
export const ContainerButtonRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: rows;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const TransmitModal = styled(Modal)`
  .custom-modal {
    max-width: 68%;
    margin: auto;
  }
  .modal-header {
    height: 100px;
    padding: 0px 80px;
    background: #fff;
    color: #58079e;
    border-bottom: transparent;

    .modal-title {
      margin-top: auto;
      width: 100%;
      /* background: red; */
      border-bottom: 1px solid #dee2e6;
      /* text-align: right; */
    }
  }
  .modal-body {
    margin-top: 20px;
    padding-left: 80px;
    padding-right: 80px;

    .modal-acoes {
      height: 70px;
      transition: 0.3s;
      cursor: pointer;

      & + .modal-acoes {
        border-top: 1px solid #ededed;
      }
      & + a {
        border-top: 1px solid #ededed !important;
      }
      h5 {
        font-size: 18px;
        color: #474747;
      }
      small {
        font-size: 13px;
      }
      svg {
        color: ${(props) => props.theme.colors.nav} !important;
      }
    }
    .executando {
      background: #ebebeb;
      transition: 0.3s;

      cursor: default;
      h5 {
        color: #a19d9d !important;
      }
      small {
        color: #a19d9d !important;
      }
      svg {
        color: #9d7dbd !important;
      }
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
    .nav-tabs {
      border: none;
      .nav-item {
        display: none;
        visibility: hidden;
      }
    }
    .button-confirm {
      margin-top: 31px;
    }
  }
  .modal-footer {
    padding-left: 80px;
    padding-right: 80px;
    margin-top: 50px;

    .actions {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;

      .left {
        display: flex;
        gap: 4px;

        .btn-impressao {
          background-color: #2d136c;
          border-color: #200e49;
          &:focus {
            box-shadow: 0 0 0 0.25rem #c2a0f8;
          }
        }
      }
      .right {
      }
    }
  }
`;
