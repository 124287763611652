import styled from 'styled-components';

export const Container = styled.div`
  user-select: none;
  width: 50%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #8850bf;
    border-radius: 5px;
    padding: 5px 15px;
    margin-bottom: 5px;
    border: solid 1px #e5e7eb;
    color: #efeeee;
    .labelHeader {
      display: flex;
      align-items: center;
      font-size: 12px;
    }

    label {
      input {
        width: 12px;
        height: 12px;
        background-color: red;
        margin-left: 3px;
        margin-top: 5px;
      }
    }
  }

  ul {
    list-style-type: none;

    margin: 0;
    padding: 0;
  }
`;

export const Main = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 350px;
  background: #fafafa;
  border: solid 2px #e5e7eb;
  border-radius: 3px;

  .isActive {
    display: none;
  }

  .rotateIcon {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  Button {
    display: flex;
    align-items: center;
    width: 100%;
    border: none;
    background: transparent;
    color: #e3e5e9;
  }

  ul .header-list {
    display: flex;
    align-items: center;
    margin: 0px;
    padding: 5px 30px;
    width: 100%;
  }
  ul .contents-list {
    cursor: pointer;
    justify-content: space-between;
    margin-bottom: 1px;
    font-size: 14px;
    color: #fff;
    background: #8850bf;
    svg {
      font-size: 16px;
      margin-right: 5px;
    }
    label {
      margin-right: 5px;
      text-transform: uppercase;
    }
    input {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  ul .contents-sub-list {
    display: flex;

    justify-content: space-between;
    margin-bottom: 1px;
    font-size: 14px;
    background: #e3e5e9;
    color: #807c7c;
    text-transform: capitalize;

    svg {
      font-size: 16px;
      margin-right: 5px;
      margin-left: 10px;
    }
    label {
      margin-right: 5px;
      cursor: pointer;
    }
    input {
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }
`;
