import React from 'react';
import { TransmitirNFeContextProvider } from './TransmitirNFeContext';
import { TransmitirNFeContent } from './TransmitirNFeContent';
import { TransmitirNFeProps } from './protocols';

export const TransmitirNFe: any = ({
  codSeqNf = undefined,
  waitForCompletion,
}: TransmitirNFeProps) => {
  return (
    <TransmitirNFeContextProvider
      codSeqNf={codSeqNf}
      waitForCompletion={waitForCompletion}
    >
      <TransmitirNFeContent />
    </TransmitirNFeContextProvider>
  );
};
