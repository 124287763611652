import React from 'react';
import {
  SplitButtonTransmitirNFe,
  ButtonTransmitirNFe,
  Container,
} from './styles';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { BsFillFileEarmarkTextFill } from 'react-icons/bs';
import { useTransmitirNFe } from './TransmitirNFeContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ModalPDF } from './components/ModalPDF';
import { Dropdown } from 'react-bootstrap';

export const TransmitirNFeContent: React.FC = () => {
  const {
    loading,
    codSeqNf,
    flgNfePendente,
    disabled,
    transmitirNFe,
    visualizarDanfe,
    isOpenModal,
  } = useTransmitirNFe();

  return (
    <Container>
      {flgNfePendente && (
        <SplitButtonTransmitirNFe
          id="dropdown-split-variants-transmissao"
          disabled={codSeqNf === undefined || disabled}
          className={flgNfePendente ? 'transmitirNfe' : 'visualizarDanfe'}
          onClick={() => {
            transmitirNFe();
          }}
          toggleLabel=""
          title={
            <div className="split-main">
              <AiOutlineCloudUpload size={18} />
              TRANSMITIR NFE
              {loading && (
                <CircularProgress
                  size={15}
                  style={{ color: '#ffffff', marginLeft: '2px' }}
                />
              )}
            </div>
          }
        >
          <Dropdown.Item
            className="dropdown-btn"
            eventKey="1"
            disabled={codSeqNf === undefined || disabled}
            onClick={() => {
              visualizarDanfe('conferencia');
            }}
          >
            Visualizar Danfe de Conferência
          </Dropdown.Item>
        </SplitButtonTransmitirNFe>
      )}
      {!flgNfePendente && (
        <ButtonTransmitirNFe
          type="button"
          className={flgNfePendente ? 'transmitirNfe' : 'visualizarDanfe'}
          disabled={codSeqNf === undefined || disabled}
          onClick={() => visualizarDanfe('final')}
        >
          <>
            <BsFillFileEarmarkTextFill size={16} />
            VISUALIZAR DANFE
            {loading && (
              <CircularProgress size={15} style={{ color: '#ffffff' }} />
            )}
          </>
        </ButtonTransmitirNFe>
      )}
      {isOpenModal && <ModalPDF />}
    </Container>
  );
};
