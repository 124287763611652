import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useTransmitirNFe } from '../../TransmitirNFeContext';

export const LeitorPDF: React.FC = () => {
  const { loadingPDF, pdf } = useTransmitirNFe();
  const [numPage, setNumPage] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPage(numPages);
    setPageNumber(1);
  }
  return (
    <>
      <Document
        className="pdf"
        renderMode="svg"
        loading=""
        file={loadingPDF ? [] : pdf}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPage), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            renderMode="svg"
            width={1080}
            pageNumber={index + 1}
          />
        ))}
      </Document>
    </>
  );
};
