import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const Container = styled.div`
  .modal-dialog {
    background: red !important;
  }
`;
export const ContainerEmail = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 30px 0;

  button {
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
`;

export const ModalActionEmail = styled(Modal)`
  z-index: 1060;
  .modal-backdrop {
    z-index: 1065;
  }
  .modal-header {
    border-bottom: transparent;
  }
`;
export const InputTagSt = styled.div`
  position: relative;
  border: 2px solid #dfe1e6;
  min-height: 42px;
  z-index: 3000;
  .Tags > span:first-child {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
  }

  .react-tagsinput-tag {
    background-color: #0052cc;
    border-radius: 2px;
    color: #ddd;
    display: inline-block;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin: 2px;
    padding: 5px;
  }
  .react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
  }

  .react-tagsinput-tag a::before {
    content: ' ✖ ';
    color: white;
  }
  input {
    display: inline-block;
    width: 100%;
    border: none;
    height: 42px;
  }
`;
