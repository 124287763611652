import styled from 'styled-components';

interface IContainer {
  inLine: boolean;
  inLinePadding: boolean;
  fontSize: string;
  isInvalid: boolean;
}
interface ILabel {
  isInvalid: boolean;
}

export const Container = styled.div<IContainer>`
  position: relative;
  height: 100%;
  /* background: green; */

  .toggle-box {
    display: flex;
    height: 100%;

    /* defines if the component will be show in line or block */
    ${(props) =>
      props.inLine === true
        ? `
          ${props.inLinePadding ? 'padding-top: 30px;' : ''}
          flex-direction: row;
          justify-content: start;
          align-items: center;
        `
        : `
          flex-direction: column;
          justify-content: space-evenly;
          align-items: start;
        `}
  }
  label[data-checked] {
    background-color: #8850bf !important;
    color: #ffffff;
  }
  /* .div--toggle {
    margin-left: -2px;
    margin-top: 2px;
  }
  @media only screen and (min-width: 992px) {
    .div--toggle {
      ${(props) =>
    props.inLine === true
      ? `
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          position: absolute;
          bottom: 13px;
          .span--text {
            margin-top: 0px;
            font-size: ${props.fontSize};
            font-style: inherit;
            line-height: 1.3333333333333333;
            color: ${props.isInvalid === true ? '#cf1919' : '#6b778c'};
            font-weight: 600;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
            cursor: pointer;
          }`
      : ''};
    }
  } */
`;

export const Label = styled.div<ILabel>`
  font-size: 0.8571428571428571em;
  font-style: inherit;
  line-height: 1.3333333333333333;
  color: ${(props) => (props.isInvalid === true ? '#cf1919' : '#6b778c')};
  font-weight: 600;
  /* margin-top: 16px; */
  /* display: inline-block; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  /* margin-bottom: 4px; */
  /* margin-top: 0; */
`;
