import React from 'react';
import { Modal, Form, Button, Row } from 'react-bootstrap';
import { CloseButton } from '../../styles';
import TagsInput from 'react-tagsinput';
import { Field } from '@atlaskit/form';
import { useTransmitirNFe } from '../../TransmitirNFeContext';
import {
  Container,
  ModalActionEmail,
  ContainerEmail,
  InputTagSt,
} from './styles';
import { AiOutlineClose } from 'react-icons/ai';
import { CircularProgress } from '@material-ui/core';

export const ModalEmail: React.FC = () => {
  const {
    isOpenModalEmail,
    setIsOpenModalEmail,
    handleSendEmail,
    tags,
    setTags,
    loadingEmail,
  } = useTransmitirNFe();

  const handleCloseModal = () => {
    setIsOpenModalEmail(false);
  };

  function handleEmail(AllEmail: string[]) {
    const emailExp = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
    const emails = [];
    // eslint-disable-next-line no-restricted-syntax
    for (let i = 0; i < AllEmail.length; i++) {
      if (emailExp.test(AllEmail[i])) {
        emails.push(AllEmail[i]);
      }
    }

    setTags(emails);
  }

  return (
    <Container>
      <ModalActionEmail
        show={isOpenModalEmail}
        onHide={handleCloseModal}
        centered
        keyboard
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Enviar Danfe
          </Modal.Title>
          <CloseButton onClick={handleCloseModal}>
            <AiOutlineClose size={24} />
          </CloseButton>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Field
                label="Pressione Enter para Confirmar"
                name="Pressione Enter para Confirmar"
              >
                {() => (
                  <InputTagSt>
                    <TagsInput
                      value={tags}
                      className="Tags"
                      onChange={(e) => handleEmail(e)}
                      inputProps={{
                        placeholder: '',
                        id: 'emails',
                      }}
                    />
                  </InputTagSt>
                )}
              </Field>
            </Row>
            <Row>
              <ContainerEmail>
                <Button
                  className="btn-impressao"
                  onClick={handleSendEmail}
                  title="Enviar"
                  disabled={!(tags.length > 0)}
                >
                  Enviar
                  {loadingEmail && (
                    <CircularProgress size={15} style={{ color: '#ffffff' }} />
                  )}
                </Button>

                <Button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleCloseModal}
                  title="Cancelar"
                >
                  Cancelar
                </Button>
              </ContainerEmail>
            </Row>
          </Form>
        </Modal.Body>
      </ModalActionEmail>
    </Container>
  );
};
