/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { useTransmitirNFe } from '../../TransmitirNFeContext';
import { LeitorPDF } from '../LeitorPDF';
import { ContainerPdf, ButtonTransmitirNFe, TransmitModal } from './styles';

import {
  AiOutlineCloudUpload,
  AiOutlineDownload,
  AiOutlineMail,
  AiOutlinePrinter,
} from 'react-icons/ai';
import { toast } from 'react-toastify';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import moment from 'moment';
import saveAs from 'file-saver';
import { ModalEmail } from '../ModalEmail';

export const ModalPDF: React.FC = () => {
  const {
    loadingModal,
    loadingPDF,
    pdf,
    transmitirNFe,
    isDanfeValidated,
    isOpenModal,
    isOpenModalEmail,
    setIsOpenModalEmail,
  } = useTransmitirNFe();
  const { setIsOpenModal } = useTransmitirNFe();
  const handleCloseModal = () => {
    return setIsOpenModal(false);
  };

  const handlePrinter = async () => {
    try {
      const byteArray = new Uint8Array(pdf.data);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const fileURL = URL.createObjectURL(blob);
      const pdfWindow = window.open();
      if (pdfWindow) {
        pdfWindow.location.href = fileURL;
      }
    } catch (error) {
      return toast.error((error as Error).message);
    }
  };

  const handleGeneratePDF = async () => {
    try {
      const byteArray = new Uint8Array(pdf.data);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const year = moment().format('YYYY');
      const month = moment().format('MM');
      const day = moment().format('DD');

      saveAs(blob, `danfe_${year}_${month}_${day}`);
    } catch (e) {
      return toast.error((e as Error).message);
    }
  };

  const openModalSendEmail = () => {
    setIsOpenModalEmail(true);
  };

  return (
    <>
      <TransmitModal
        show={isOpenModal}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // size="xl"
        dialogClassName="custom-modal"
      >
        <Modal.Header>
          <Modal.Title>
            {!isDanfeValidated ? 'DANFE DE CONFERÊNCIA' : 'DANFE'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContainerPdf>
            <div style={{ height: '5px' }}>
              {loadingPDF && (
                <LinearProgress
                  style={{
                    background: '#c2a0f8',
                    color: '#8850BF',
                  }}
                />
              )}
            </div>
            <LeitorPDF />
          </ContainerPdf>
        </Modal.Body>
        <Modal.Footer>
          <div className="actions">
            <div className="left">
              <Button
                className="btn-impressao"
                onClick={handlePrinter}
                title="Imprimir"
              >
                Imprimir
                <AiOutlinePrinter size={24} className="btn-icon" />
              </Button>
              <Button onClick={handleGeneratePDF} title="download">
                Download
                <AiOutlineDownload size={24} className="btn-icon" />
              </Button>
            </div>
            <div className="right">
              {isDanfeValidated && (
                <Button
                  onClick={openModalSendEmail}
                  title="Reenviar Email"
                  className="btn-email"
                >
                  Reenviar Email
                  <AiOutlineMail size={24} className="btn-icon" />
                </Button>
              )}
              {!isDanfeValidated && (
                <ButtonTransmitirNFe
                  type="button"
                  className="transmitirNfe"
                  onClick={transmitirNFe}
                >
                  TRANSMITIR NFE PARA SEFAZ
                  <AiOutlineCloudUpload size={16} />
                  {loadingModal && (
                    <CircularProgress size={15} style={{ color: '#ffffff' }} />
                  )}
                </ButtonTransmitirNFe>
              )}
            </div>
          </div>
        </Modal.Footer>
      </TransmitModal>
      {isOpenModalEmail && <ModalEmail />}
    </>
  );
};
