import Toggle from '@atlaskit/toggle';
import React from 'react';

import { Container, Label } from './styles';

type HTMLInputProps = Pick<
  React.AllHTMLAttributes<HTMLInputElement>,
  'id' | 'name'
>;

interface FormProps extends HTMLInputProps {
  size?: 'regular' | 'large';
  disabled?: boolean;
  labelText: string;
  setChecked: boolean;
  inLine?: boolean;
  inLinePadding?: boolean;
  keepInLine?: boolean;
  fontSize?: string;
  isInvalid?: boolean;
  onSwitch(): void;
  labelOnRight?: boolean;
  toggleTitle?: string;
}
/**
 * @param labelText Label para o toggle button
 * @param setChecked Valor booelano que dita se o toggle está marcado ou não
 * @param size Define o tamanho do toggle
 * @param inLine Define se a label ficará acima ou em frente ao toggle
 * @param onSwitch Função responsável por alterar o estado do toggle para marcado ou não
 * @param fontSize Define o tamanho da fonte da Label
 * @param inLinePadding Ajusta o padding caso
 * @param labelOnRight Diz se o label será apresentado ao lado direito do toggle
 * @returns Componente Toggle com label ao topo
 */
const ToggleDefault: React.FC<FormProps> = (props) => {
  const {
    labelText,
    setChecked,
    onSwitch,
    size = 'regular',
    disabled = false,
    inLine = false,
    inLinePadding = false,
    keepInLine = false,
    isInvalid = false,
    fontSize = '0.8571428571428571em',
    labelOnRight = false,
    toggleTitle = '',
    ...rest
  } = props;

  return (
    <Container
      inLine={inLine}
      inLinePadding={inLinePadding}
      fontSize={fontSize}
      isInvalid={isInvalid}
      title={toggleTitle}
    >
      <div className="toggle-box">
        {!labelOnRight && <Label isInvalid={isInvalid}>{labelText}</Label>}

        <Toggle
          // id="toggle-default"
          size={size}
          isChecked={setChecked}
          isDisabled={disabled}
          onChange={() => onSwitch()}
          {...rest}
        />

        {labelOnRight && <Label isInvalid={isInvalid}>{labelText}</Label>}
      </div>
    </Container>
  );
};

export default ToggleDefault;
