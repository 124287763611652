/* eslint-disable react/destructuring-assignment */
import React from 'react';

type SpanProps = {
  value: string | number | undefined;
  className?: string;
};

export function Span(props: SpanProps): any {
  return (
    <div className="divSpan">
      <span>{props.value}</span>
    </div>
  );
}
